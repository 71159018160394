import { ThemeProvider } from 'next-themes'
import type { AppProps } from 'next/app'
import React from 'react'
import { GlobalStyles } from 'twin.macro'

import { Theme } from 'lib/constants'
import GlobalCustomStyles from 'styles/global'

import 'ag-grid-community/styles/ag-grid.css'
import 'components/Blotter/ag-scrypt-blotter.theme.css'

// enable API mocks
if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
  require('../mocks')
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <GlobalStyles />
      <GlobalCustomStyles />
      <ThemeProvider
        attribute="class"
        themes={[Theme.LIGHT, Theme.DARK]}
        forcedTheme={Theme.DARK}
      >
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

export default App
